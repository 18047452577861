import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { useCookies } from "react-cookie";
import globalStates from "../../context/globalStates";
import PhoneInput from "react-phone-number-input/input";
import PhoneInputWithFlags from "react-phone-number-input";
import "react-phone-number-input/style.css";
import TagManager from "react-gtm-module";
import GlobalStates from "../../context/globalStates";
import { encryptData, decryptData } from "./../../helper/helper";
import ReCAPTCHA from "react-google-recaptcha";

function formError() {
  var errorElement = window.jQuery("#contactForm");
  var scrollPosition = errorElement.offset().top - 120;
  window.jQuery("html, body").animate({ scrollTop: scrollPosition }, 500);
  errorElement
    .removeClass()
    .addClass("shake animated")
    .one(
      "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
      function () {
        errorElement.removeClass();
      }
    );
}

function submitMSG(valid, msg) {
  if (valid) {
    var msgClasses = "success form-message";
  } else {
    var msgClasses = "error form-message";
  }
  window.jQuery("#msgSubmit").removeClass().addClass(msgClasses).text(msg);
}

var checkTcNum = function (value) {
  value = value.toString();
  var isEleven = /^[0-9]{11}$/.test(value);
  var totalX = 0;
  for (var i = 0; i < 10; i++) {
    totalX += Number(value.substr(i, 1));
  }
  var isRuleX = totalX % 10 == value.substr(10, 1);
  var totalY1 = 0;
  var totalY2 = 0;
  for (var i = 0; i < 10; i += 2) {
    totalY1 += Number(value.substr(i, 1));
  }
  for (var i = 1; i < 10; i += 2) {
    totalY2 += Number(value.substr(i, 1));
  }
  var isRuleY = (totalY1 * 7 - totalY2) % 10 == value.substr(9, 0);
  return isEleven && isRuleX && isRuleY;
};

const Form = (props) => {
  const { agreementsData } = props;

  const [verify, setVerify] = useState(false);
  const [kvkCheck, setKvkCheck] = useState(false);
  const [experienced, setExperienced] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);

  const [tcno, setTcno] = useState("");

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");

  const [phone, setPhone] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [timer, setTimer] = useState("");
  const [intervalSet, setIntervalSet] = useState([]);
  const { startFetch, response } = useRequest();
  const [cookies, setCookie] = useCookies(["token", "step", "TC"]);
  const { innerTexts } = useContext(globalStates);
  const { slugify } = useContext(GlobalStates);

  const [elektronikIletiIzni, setElektronikIletiIzni] = useState(null);
  const [urlClickedCount, setUrlClickedCount] = useState(0);
  const [captchaResponse, setCaptchaResponse] = useState();
  const [isLocal, setIsLocal] = useState();
  const regex = /^\+905\d{9}$/;

  const [allbatrossFirstContract, setAllbatrossFirstContract] = useState(false);
  const [allbatrossSecondContract, setAllbatrossSecondContract] =
    useState(false);

  useEffect(() => {
    setIsLocal(
      window?.location?.href?.includes("localhost") ||
        window?.location?.href?.includes("optimusyazilim")
    );
  }, [window?.location?.href]);

  const handleCaptchaVerify = (response) => {
    setCaptchaResponse(response);
    setButtonDisabled(false);
  };

  const handleCaptchaExpired = () => {
    alert("CAPTCHA süresi doldu. Lütfen tekrar işaretleyin.");
    setCaptchaResponse(null);
  };

  const history = useHistory();
  const Login = (e) => {
    e.preventDefault();
    if (!checkTcNum(tcno)) {
      formError();
      submitMSG(false, "Geçersiz TC numarası");
    } else if (
      innerTexts.id === "ALLBATROSS" &&
      (!allbatrossFirstContract || !allbatrossSecondContract)
    ) {
      formError();
      submitMSG(
        false,
        "Aydınlatma ve Açık Rıza Metni’ ni işaretlemeniz gerekmektedir!!"
      );
    } else {
      if (kvkCheck) {
        if (innerTexts.portfoy && innerTexts.id !== "HEDEF") {
          if (innerTexts.experienced && !experienced) {
            formError();
            return submitMSG(
              false,
              "Lütfen nitelikli bir yatırımcı olduğunuzu onaylayınız."
            );
          }
          if (innerTexts.id === "HEDEF" && !experienced) {
            formError();
            return submitMSG(
              false,
              "Lütfen nitelikli bir yatırımcı olduğunuzu onaylayınız."
            );
          }
          if (name === "" || lastName === "") {
            formError();
            return submitMSG(false, "Ad Soyad alanı boş geçilemez");
          }
        }

        if (!phone || (phone.startsWith("+90") && !regex.test(phone))) {
          formError();
          return submitMSG(
            false,
            "Lütfen telefon numaranızın (+90 5xx xxx xx xx) formatına uyduğundan emin olunuz!"
          );
        }

        if (innerTexts.isCaptchaVisible && buttonDisabled) {
          formError();
          return submitMSG(false, "Lütfen Captcha kutusunu işaretleyin!");
        }

        if (innerTexts.id === "OSMANLIYATIRIM" && !popupVisible) {
          setPopupVisible(true);
          return;
        }

        const encryptedCepNo = encryptData(phone.replace("+90", ""));
        setCookie("CEP_NO", encryptedCepNo, { path: "/" });

        var urlencoded = new URLSearchParams();
        urlencoded.append("tc_no", tcno);
        urlencoded.append(
          "cep_no",
          innerTexts.id === "INFOYATIRIM" || innerTexts.id === "A1CAPITAL"
            ? phone.replace("+90", "0")
            : phone.replace("+90", "")
        );
        urlencoded.append(
          "nitelikli_yatirimci",
          innerTexts.experienced && experienced ? 1 : 0
        );

        urlencoded.append("elektronik_ileti_izni", elektronikIletiIzni);
        urlencoded.append("isCaptchaVisible", innerTexts.isCaptchaVisible);
        if (innerTexts.isCaptchaVisible) {
          urlencoded.append("g-recaptcha", captchaResponse);
        }

        // for(var i = 0; i < agreementsData?.length; i++) {
        //     urlencoded.append("AgreementsCode", agreementsData[i].Code)
        // }

        if (innerTexts.portfoy) {
          urlencoded.append("ad", slugify(name));
          urlencoded.append("soyad", slugify(lastName));
        }

        const urlSearch = window.location.search.substring(1);
        if (urlSearch !== "") {
          const urlSearchArr = urlSearch.split("&");
          for (let i = 0; i < urlSearchArr.length; i++) {
            const keyValue = urlSearchArr[i].split("=");
            if (keyValue.length === 2) {
              urlencoded.append(keyValue[0], keyValue[1]);
            }
          }
        }

        startFetch(
          "islem=sms",
          "POST",
          urlencoded,
          "application/x-www-form-urlencoded"
        );
        setPopupVisible(false);
      } else {
        formError();
        submitMSG(
          false,
          "Lütfen gizlilik sözleşmesini onayladığınızı doğrulayınız"
        );
      }
    }
  };

  useEffect(() => {
    if (allbatrossFirstContract && allbatrossSecondContract) {
      setKvkCheck(true);
    }
  }, [allbatrossFirstContract, allbatrossSecondContract]);

  const tryVerify = (e) => {
    e.preventDefault();
    if (verifyCode !== "") {
      var urlencoded = new URLSearchParams();
      urlencoded.append("verify_code", verifyCode);
      urlencoded.append("tc_no", tcno);

      startFetch(
        "islem=sms_verify",
        "POST",
        urlencoded,
        "application/x-www-form-urlencoded"
      );
      return;
    }
    submitMSG(true, "Geçersiz güvenlik kodu");
  };
  const startTimer = (duration) => {
    window.clearInterval(intervalSet[0]);
    var timer = duration * 60,
      minutes,
      seconds;
    var interval = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      if (seconds === "00" && minutes === "00") {
        if (window.location.pathname !== "/register") {
          window.location.replace("/");
        }
      }
      setTimer(minutes + ":" + seconds);

      if (--timer < 0) {
        timer = duration * 60;
      }
    }, 1000);
    intervalSet.push(interval);
    setIntervalSet(intervalSet);
  };

  useEffect(() => {
    if (response) {
      if (response.errNo === 0) {
        if (response.token) {
          setCookie("token", response.token, { path: "/" });
          const encryptedTc = encryptData(tcno);
          setCookie("TC", encryptedTc, { path: "/" });
          setCookie("step", response.step, { path: "/" });
          history.replace("/register");
        } else {
          setVerify(true);
          if (props.sendVerifyStatus !== undefined) {
            props.sendVerifyStatus(true);
          }
          startTimer(3);
        }
      }
      if (response.errNo < 0) {
        submitMSG(true, response.errDesc);
      }
    }
  }, [response]);

  const loadScript = () => {
    if (innerTexts.id === "ALB") {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://assets.cookieseal.com/cookie-seal.js";
      script.id = "third-party-script-async";
      document.head.appendChild(script);

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = "https://assets.cookieseal.com/cookie-seal.css";
      document.head.appendChild(link);
    }
  };

  if (document.getElementById("third-party-script-async") === null) {
    loadScript();
  }

  return (
    <>
      {verify && innerTexts.id === "IKON" ? (
        <>
          <strong style={{ fontSize: "large", textAlign: "center" }}>
            Lütfen cep telefonunuza gelen 6 haneli kodu bu alana giriniz.
          </strong>
          <br /> <br />
        </>
      ) : verify && innerTexts.id === "HEDEF" ? (
        <>
          <strong style={{ fontSize: "large", textAlign: "center" }}>
            Telefon numaranıza iletilen SMS doğrulama kodunu giriniz.
          </strong>
          <br /> <br />
        </>
      ) : innerTexts.id === "HEDEF" ? null : (
        <>
          <strong style={{ fontSize: "large", textAlign: "center" }}>
            TC Kimlik Numaranız ve Cep Telefonunuzu girerek hemen hesap açma
            işlemine başlayınız.
          </strong>
          <br />
        </>
      )}
      <form
        id="contactForm"
        data-toggle="validator"
        style={{ overflow: "hidden" }}
      >
        {verify ? (
          <>
            <div className="form-group">
              <label>{innerTexts.label_verify}:</label>
              <input
                value={verifyCode}
                onChange={(e) => {
                  setVerifyCode(e.target.value);
                }}
                style={{
                  textAlign: "left",
                  marginLeft: "5rem",
                }}
                type="tel"
                id="verify"
                maxLength="6"
                placeholder="_ _ _ _ _ _"
                className="form-control"
                required
              />
              <label className="verifyLabel">
                <span style={{ color: "green", textAlign: "center" }}>
                  {timer}
                </span>
              </label>
            </div>
            <div id="msgSubmit" className="form-message hidden"></div>
            <button
              onClick={(e) => {
                tryVerify(e);
              }}
              type="submit"
              className="form_button btn btn-default btn-primary btn-block"
              id="form-submit"
            >
              Doğrula
            </button>
          </>
        ) : (
          <>
            {innerTexts?.portfoy && innerTexts?.id !== "HEDEF" && (
              <>
                <div className="form-group-short">
                  <label>{innerTexts.label_step_one_input_name}: </label>
                  <input
                    onChange={(e) => {
                      if (e.target.value.length <= 30) {
                        setName(e.target.value.toLocaleUpperCase());
                      }
                    }}
                    autoComplete="false"
                    id="ad"
                    value={name.toLocaleUpperCase()}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group-short">
                  <label>{innerTexts.label_step_one_input_lastname}: </label>
                  <input
                    onChange={(e) => {
                      if (e.target.value.length <= 30) {
                        setLastName(e.target.value.toLocaleUpperCase());
                      }
                    }}
                    autoComplete="false"
                    value={lastName.toLocaleUpperCase()}
                    id="lastname"
                    className="form-control"
                    required
                  />
                </div>
              </>
            )}
            <div className="form-group">
              <label>{innerTexts.label_tckn}: </label>
              <input
                onChange={(e) => {
                  if (e.target.value.length <= 11) {
                    setTcno(e.target.value);
                  }
                }}
                autoComplete="false"
                value={tcno}
                type="number"
                id="tcno"
                className="form-control tcnoInput"
                required
              />
            </div>
            <div className="form-group">
              <label>{innerTexts.label_phone}:</label>
              {innerTexts.id === "OSMANLIYATIRIM" ||
              innerTexts.id === "INFOYATIRIM" ||
              innerTexts.id === "DINAMIK" ? (
                <PhoneInputWithFlags
                  placeholder="Telefon Numarası"
                  international={true}
                  countryCallingCodeEditable={false}
                  defaultCountry="TR"
                  value={phone}
                  className="form-control"
                  onChange={(e) => {
                    setPhone(e);
                  }}
                  required
                  maxLength={17}
                />
              ) : (
                <PhoneInput
                  placeholder="Telefon Numarası"
                  international={true}
                  countryCallingCodeEditable={false}
                  withCountryCallingCode={true}
                  country="TR"
                  value={phone}
                  className="form-control"
                  onChange={(e) => {
                    setPhone(e);
                  }}
                  required
                  maxLength={17}
                />
              )}
            </div>
            <div id="msgSubmit" className="form-message hidden"></div>
            {innerTexts.portfoy && (
              <div className="form-check contract">
                <label
                  style={{
                    display: "contents",
                    fontWeight: "lighter",
                    textAlign: "justify",
                  }}
                  className="form-check-label"
                  htmlFor="flexCheckDefault2"
                >
                  <input
                    onChange={(e) => {
                      setExperienced(e.target.checked);
                      if (
                        innerTexts.id === "ISTANBULPORTFOY" ||
                        innerTexts.id === "HEDEF" ||
                        innerTexts.id === "DINAMIK"
                      ) {
                        window.open(innerTexts.label_experienced_url, "_blank");
                      }
                    }}
                    style={{ marginRight: 5 }}
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault2"
                  />

                  <div
                    dangerouslySetInnerHTML={{
                      __html: innerTexts.label_experienced_text_html,
                    }}
                  ></div>
                </label>
              </div>
            )}
            <>
              {innerTexts.id !== "ALLBATROSS" && (
                <div
                  className="form-check contract"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <label
                    style={{
                      display: "contents",
                      fontWeight: "lighter",
                      textAlign: "justify",
                    }}
                    className="form-check-label login-agreements-input"
                    htmlFor="agreementCheckbox"
                  >
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        alignContent: "end",
                      }}
                    >
                      <input
                        onClick={(e) => {
                          if (innerTexts.id === "ISTANBULPORTFOY") {
                            if (urlClickedCount === 0) {
                              window.open(
                                innerTexts.label_privacy_url_2,
                                "_blank"
                              );
                              setKvkCheck(true);
                            }
                            setElektronikIletiIzni("H");
                          } else if (innerTexts.id === "DINAMIK") {
                            window.open(
                              innerTexts.label_privacy_url_1,
                              "_blank"
                            );
                            setUrlClickedCount(1);
                            setKvkCheck(true);
                            setElektronikIletiIzni("H");
                            if (urlClickedCount === 1) {
                              e.preventDefault();
                            }
                          } else if (innerTexts.id === "HEDEF") {
                            window.open(
                              innerTexts.label_privacy_url_1,
                              "_blank"
                            );
                            setKvkCheck(true);
                          } else {
                            setKvkCheck(!kvkCheck);
                          }
                        }}
                        defaultChecked={kvkCheck}
                        disabled={innerTexts.id === "IKON" && kvkCheck}
                        style={{ marginRight: 5 }}
                        className="form-check-input"
                        type="checkbox"
                        id="agreementCheckbox"
                      />
                    </div>

                    <div
                      className="agreement_links"
                      dangerouslySetInnerHTML={{
                        __html: innerTexts.label_privacy_text_html,
                      }}
                    >
                      {/* <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                            {
                                                agreementsData?.map((item, index) => {
                                                    return (
                                                        <li 
                                                            onClick={
                                                                () => window.open(!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? `http://localhost:3001/webapi/Default.aspx?islem=show_registration_agreements&agreementCode=${item.Code}` : `/webapi/Default.aspx?islem=show_registration_agreements&agreementCode=${item.Code}`, '_blank')
                                                            } 
                                                            key={index}
                                                            style={{ display: 'inline-block', marginRight: 5 }}>
        
                                                            <a href="#" style={{ textDecoration: 'underline', color: 'inherit' }}>{item.Name}</a>
                                                            {agreementsData?.length - 1 !== index ?
                                                                <span>
                                                                    {
                                                                        agreementsData?.length - 2 === index ?
                                                                            <span> ve </span> : <span>, </span>
                                                                    }
                                                                </span> :
                                                                <span>'ni okudum ve kabul ediyorum.</span>}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul> */}
                    </div>
                  </label>
                </div>
              )}

              {innerTexts.id === "ALLBATROSS" && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="form-check contract"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <label
                      style={{
                        display: "contents",
                        fontWeight: "lighter",
                        textAlign: "justify",
                      }}
                      className="form-check-label login-agreements-input"
                      htmlFor="allbatrossAggrement1"
                    >
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          alignContent: "end",
                        }}
                      >
                        <input
                          style={{ marginRight: 5 }}
                          className="form-check-input"
                          type="checkbox"
                          defaultChecked={allbatrossFirstContract}
                          required
                          id="allbatrossAggrement1"
                          onChange={(e) => {
                            setAllbatrossFirstContract(e.target.checked);
                          }}
                          onClick={(e) => {
                            window.open(
                              innerTexts.label_privacy_url_1,
                              "_blank"
                            );
                          }}
                        />
                      </div>

                      <div
                        className="agreement_links"
                        dangerouslySetInnerHTML={{
                          __html: innerTexts.label_privacy_text_html,
                        }}
                      ></div>
                    </label>
                  </div>

                  <div
                    className="form-check contract"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <label
                      style={{
                        display: "contents",
                        fontWeight: "lighter",
                        textAlign: "justify",
                      }}
                      className="form-check-label login-agreements-input"
                      htmlFor="allbatrossAggrement2"
                    >
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          alignContent: "end",
                        }}
                      >
                        <input
                          style={{ marginRight: 5 }}
                          className="form-check-input"
                          type="checkbox"
                          defaultChecked={allbatrossSecondContract}
                          required
                          id="allbatrossAggrement2"
                          onChange={(e) => {
                            setAllbatrossSecondContract(e.target.checked);
                          }}
                          onClick={(e) => {
                            window.open(
                              innerTexts.label_privacy_url_2,
                              "_blank"
                            );
                          }}
                        />
                      </div>

                      <div
                        className="agreement_links"
                        dangerouslySetInnerHTML={{
                          __html: innerTexts.label_privacy_text_html_2,
                        }}
                      ></div>
                    </label>
                  </div>
                </div>
              )}
            </>

            {innerTexts.label_privacy_text_contact_permission_html &&
              innerTexts.elekIzinChkbox && (
                <div
                  className="mb-3 form-check contract"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      fontWeight: "lighter",
                      textAlign: "justify",
                    }}
                    className="form-check-label login-agreements-input"
                    htmlFor="flexCheckDefault"
                  >
                    <div style={{ width: "30px", height: "30px" }}>
                      <input
                        style={{ marginRight: 5 }}
                        className="form-check-input"
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setElektronikIletiIzni(e.target.value);
                          } else {
                            setElektronikIletiIzni(null);
                          }
                        }}
                        onClick={(e) => {
                          if (!e.target.checked) {
                            return;
                          }
                          window.open(
                            innerTexts.label_privacy_text_contact_permission_url ||
                              innerTexts.label_privacy_text_contact_permission_html,
                            "_blank"
                          );
                        }}
                        value="H"
                        id="flexCheckDefault"
                      />
                    </div>

                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          innerTexts.label_privacy_text_contact_permission_html,
                      }}
                    ></div>
                  </label>
                </div>
              )}

            {innerTexts.isCaptchaVisible && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h5 style={{ color: "white" }}>
                  Kayıt olabilmek için kutuyu işaretlemelisiniz.
                </h5>
                <ReCAPTCHA
                  style={{ paddingBottom: "3vh" }}
                  sitekey={
                    isLocal
                      ? innerTexts.gCaptchaTestKey
                      : innerTexts.gCaptchaKey
                  }
                  onChange={handleCaptchaVerify}
                  onExpired={handleCaptchaExpired}
                />
              </div>
            )}

            <button
              onClick={(e) => {
                Login(e);
              }}
              type="submit"
              className="form_button btn btn-default btn-primary btn-block"
              id="form-submit"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!popupVisible ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <span style={{ whiteSpace: "normal", maxWidth: "200px" }}>
                    {innerTexts.btn_text_login}
                  </span>
                </div>
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <span
                    className="approvalText"
                    style={{ whiteSpace: "normal", maxWidth: "200px" }}
                  >
                    TC ve Cep Telefonu numaralarımı tekrar kontrol ettim
                  </span>
                </div>
              )}
            </button>
          </>
        )}
      </form>
    </>
  );
};
export default Form;
