
import React, { useContext } from 'react'
import globalStates from '../../context/globalStates'
import {getBrowserAlertText} from "../../helper/helper";

const AhlatciMenkulHeader = ({ children }) => {
  const { innerTexts } = useContext(globalStates)

  return (
      <>
        <div className="bg">
          <header>
            <div className="container">
              <div className="row">
                <div className="col-xs-7 col-sm-3 col-md-5">
                  <a href="/" className="logo">
                    {/* <img src="/images/logo.svg" alt="logo" /> */}
                    ​<picture>
                    <source srcSet="/images/logo.svg" type="image/svg+xml"/>
                    <img style={{maxWidth: '100%', width: '15em'}} src="/images/logo.png" alt="logo"/>
                  </picture>
                  </a>
                </div>
                <div className="col-xs-5  col-sm-9 col-md-7">
                  <div className="header_right">
                    <a href={`mailto:${innerTexts.companyEmail}`} className="hr_box">
                      <img src="/images/mail.svg" alt="" />
                      {innerTexts.companyEmail}
                    </a>

                    <a href={innerTexts.companyPhoneLink} className="hr_box">
                      <img src="/images/phone.svg" alt="" />
                      {innerTexts.companyPhone}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </header>
          {getBrowserAlertText() ? <div className="container-fluid information" style={{textAlign: "center"}}>
            {getBrowserAlertText()}
          </div> : null}
          {children}
        </div>
      </>
  )

}
export default AhlatciMenkulHeader
