import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import TagManager  from 'react-gtm-module'
import * as serviceWorker from './serviceWorker';
import './App.css'
import ReactGA from 'react-ga';

var dotenv = require('dotenv');
var dotenvExpand = require('dotenv-expand');
var myEnv = dotenv.config();

dotenvExpand(myEnv);

// if (process.env.REACT_APP_BUILDTYPE === "INFOYATIRIM") {
//   TagManager.initialize({ gtmId: 'GTM-W9J56JN' });
// }

if (process.env.REACT_APP_BUILDTYPE === "A1CAPITAL") {
    TagManager.initialize({ gtmId: 'GTM-WFJNBPP' });
}

if (process.env.REACT_APP_BUILDTYPE === "INFOYATIRIM") {
    TagManager.initialize({ gtmId: 'AW-468162055' });
}

if (process.env.REACT_APP_BUILDTYPE === "ALB") {
  TagManager.initialize({ gtmId: 'GTM-TNRJQP4' });
  // eski code
  // ReactGA.initialize('UA-38101137-12');
  // ReactGA.pageview(window.location.pathname + window.location.search);
}

if(process.env.REACT_APP_BUILDTYPE === "OSMANLI") {
    TagManager.initialize({ gtmId: 'GTM-T5CQKGVN' });
}

if (process.env.REACT_APP_BUILDTYPE === "INVESTAZ") {
  TagManager.initialize({ gtmId: 'GTM-5LKC8DD' });
}

if (process.env.REACT_APP_BUILDTYPE === "IKON") {
  ReactGA.initialize('G-MNMVYWHMHE');
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
